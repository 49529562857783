form {
    label {
        &.required {
            position: relative;
            &:after {
                content: "*";
                color: var(--bs-red);
                padding-left: rem(4);
            }
        }
    }
    .is-invalid {
        .custom-react-select__control {
            border-color: var(--bs-red) !important;
        }
    }
    .custome-input {
        border: 1.5px solid #dfe0e1 !important;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
        min-height: 48px !important;
        background-color: white;
        padding: rem(5) rem(10)!important;
        &:hover {
            cursor: pointer;
        }
        &-list {
            background-color: white!important;
            min-height: 40px !important;
            &:hover {
                cursor: inherit;
            }
        }
    }
}

.toggle {
    cursor: pointer;
    color: #408dfb;
    font-weight: 400;
    font-size: 13px;
}

.cut-multi-select {
    .custom-react-select__value-container{
        flex-wrap: nowrap !important;
    }

    .custom-react-select__multi-value{
        min-width: 25% !important;
    }
}

#ReactSimpleImageViewer {
    z-index: 1500 !important;
    .react-simple-image-viewer {
        &__previous {
            opacity: 0.9;
        }
    }
    .react-simple-image-viewer {
        &__close {
            opacity: 0.9;
        }
    }
    .react-simple-image-viewer {
        &__next {
            opacity: 0.9;
        }
    }
}
.opacity4 {
    opacity: 0.4;
}
input[type="text"]:disabled {
    cursor: not-allowed;
}

.phone-hint {
  bottom: rem(-20);
}
