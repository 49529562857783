@import '../../../assets/scss/utils/functions.scss';

.modal {
    &-dialog {
        .modal-header {
            .btn-close {
                top: rem(15);
                right: rem(15);
            }
        }
    }
}