.modal {
    &.change-email {
        .modal-dialog {
            max-width: rem(550);
        }
    }
}

.modal.fade {
    background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
    opacity: 0;
}

.notification-modal {
  &-textarea {
    height: rem(130);
    resize: none;
  }
}

