// Media queries
@mixin media($breakpoint) {
    @if $breakpoint == "sm" {
      @media screen and (min-width: 576px) {
        @content;
      }
    }

    @else if $breakpoint == "md" {
      @media screen and (min-width: 768px) {
        @content;
      }
    }

    @else if $breakpoint == "lg" {
        @media screen and (min-width: 992px) {
        @content;
      }
    }

    @else if $breakpoint == "xl" {
        @media screen and (min-width: 1200px) {
        @content;
      }
    }
    
    @else if $breakpoint == "xxl" {
        @media screen and (min-width: 1440px) {
        @content;
      }
    }
}

@mixin simpleTransition() {
  transition: 0.25s ease-in-out;
}

@mixin resetButtonFocusStyle {
  transition: 0.25s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}