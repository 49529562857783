@use "./src/assets/scss/utils/mixins" as mixin;
@use "./src/assets/scss/utils/functions" as func;

nav {
  padding: 0!important;
  position: fixed!important;
  z-index: 100;
  width: 100%;
  @include mixin.media(md) {
    position: static!important;
  }
  .nav-link {
    font-size: func.rem(13);
    @include mixin.media(lg) {
      font-size: func.rem(11);
    }
    @include mixin.media(xl) {
      font-size: func.rem(13);
    }
  }
  .dropdown-menu {
    padding-left: func.rem(20);
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    @include mixin.media(md) {
      padding-left: func.rem(5);
    }
    .nav-link {
      padding-top: func.rem(9);
      padding-bottom: func.rem(9);
      @include mixin.media(md) {
        padding-top: func.rem(11);
        padding-bottom: func.rem(11);
      }
    }
  }
  .navbar-brand {
    @include mixin.media(lg) {
      font-size: 1.3vw;
    }
    @include mixin.media(xl) {
      font-size: func.rem(18);
    }
  }

  .logo {
    width: func.rem(60);
    height: func.rem(60);
    @include mixin.media(md) {
      width: func.rem(75);
      height: func.rem(75);
    }
  }

  .navbar-toggler {
    border: 1px solid gray;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .icon-bar {
    background-color: #888;
    width: func.rem(35);
    height: func.rem(4);
    display: grid;
    margin-top: func.rem(5);
    margin-bottom: func.rem(5);
    border-radius: func.rem(1);
    transition: all .2s;
  }

  .change-toggle {
    .top-bar {
      transform: translate(0, func.rem(9)) rotate(-45deg);
    }
    .middle-bar {
      opacity: 0;
    }
    .bottom-bar {
      transform: translate(0, func.rem(-9)) rotate(45deg);
    }
  }

  .active-timer {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 34px;
    height: 34px;
    color: #ec2941;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    line-height: 24px;
  }

  .active-timer::after {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    display: inline-block;
    width: 34px;
    height: 34px;

    background-color: #fff;
    border-radius: 70%;
    animation: pulse 400ms cubic-bezier(0.25, 0.1, 0.25, 0.1);
    animation-fill-mode: forwards;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }

}
