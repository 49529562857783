@use "./src/assets/scss/utils/mixins" as mixin;
@use "./src/assets/scss/utils/functions" as func;

//extended  existing bootstrap common classes
body {
  font-size: 0.9rem !important;
}

.w-md-auto {
  width: 100%;
  @include mixin.media(md) {
    width: auto;
  }
}

.w-lg-auto {
  width: 100%;
  @include mixin.media(lg) {
    width: auto;
  }
}

.overflow-info {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn-sm {
  --bs-btn-padding-y: 0.4rem !important;
  --bs-btn-padding-x: 0.8rem !important;
  --bs-btn-font-size: 0.6rem !important;
}

.height-content {
  height: fit-content;
}

#root {
  min-height: 100vh;
  justify-content: space-between;
}

.w-fit {
  width: fit-content;
}

.spinner-border-modal {
  --bs-spinner-width: 1.5rem!important;
  --bs-spinner-height: 1.5rem!important;
}

.text-right {
  text-align: right;
}

.text-lg-start {
  @include mixin.media(lg) {
    text-align: start;
  }
}


.img-sm {
  width: 120px !important;
  max-width: 100% !important;
  height: auto !important;
  cursor: pointer;
}

.img-md {
  width: auto;
  height: auto;
  cursor: pointer;
  object-fit: contain;
  @include mixin.media(md) {
    max-height: 160px !important;
    width: 100% !important;
    height: 100% !important;
    max-width: 210px !important;
  }
}

.border-light {
  border-bottom: 1px solid #f0eaea !important;
}

.right-10 {
  right: 10px !important;
}

.right-50 {
  right: 50px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.f12 {
  font-size: rem(12);
}

.f20 {
  font-size: rem(20);
}

.styles-module_image__2hdkJ {
  max-height: 100%;
  max-width: 90%!important;
  @include mixin.media(md) {
    max-height: 100%;
    max-width: 100%;
  }
}

.customMarker{
  padding: 2px 3px;
  background-color: white;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid gray;
  margin-bottom: 55px;
  font-size: 11px!important;
}

.text-black {
  color: black;
}

.table td {
  padding: 10px !important;
  font-size: 14px !important;
}

.right-0 {
  right: 0;
}

.rotate90 {
 transform: rotate(90deg);
}

.fs-7 {
  font-size: rem(9);
}

.table-sub-actions {
  transform: translateY(25px);
}

.mw-85 {
  max-width: 85%;
}

.report-select {
  max-width: rem(300);
  width: rem(250);
}

.btn-nulled {
  border: 0 !important;
  outline: 0 !important;
  background: transparent !important;
}

.flex-16 {
  flex: 1 1 16.6666666667%;
}

.flex-33 {
  flex: 1 1 33.3333%;
}

.flex-25 {
  flex: 1 1 25%;
}

.flex-50 {
  flex: 1 1 50%;
}

.border-right {
  border-right: 1px solid #f0eaea !important;
}

.border-right-2 {
  border-right: 2px solid #f0eaea !important;
}

.border-right-0{
  border-right: 0 solid #f0eaea !important;
}

.border-left-0 {
  border-left: 0 solid #f0eaea !important;
}

.border-left {
  border-left: 1px solid #f0eaea !important;
}

.border-left-2 {
  border-left: 2px solid #f0eaea !important;
}

.border-top-0 {
  border-top: 0 solid #f0eaea !important;
}

.border-top {
  border-top: 1px solid #f0eaea !important;
}

.border-top-2 {
  border-top: 2px solid #f0eaea !important;
}

.border-bottom-0 {
  border-bottom: 0 solid #f0eaea !important;
}

.border-bottom {
  border-bottom: 1px solid #f0eaea !important;
}

.border-bottom-grey {
  border-bottom: 1px solid #696868 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #f0eaea !important;
}

.list-style-type-none {
  list-style-type: none;
}

.print-logo {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.font-weight-medium {
  font-weight: 500 !important;
}

@media print {
  .pagebreak { page-break-before: always; clear: both; }
  .print-logo {
    opacity: 100%;
  }

  .print-subtotal {
    font-size: 12px;
  }
}


.gm-style-iw-chr {
  .gm-ui-hover-effect {
    position: absolute !important;
    right: 0;
  }
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

table {
  .btn {
    font-size: 13px!important;
  }
}

.table-sm {
  .table td, .table th {
    padding: 1.2rem !important;
  }

  .btn {
    font-size: 13px!important;
  }
}
