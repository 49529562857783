.app-loader {
    background-color: white;
    z-index: 2000 !important;
    height: 100vh;
    top: 91.875px;
    bottom: 60px;
}

.app-loader_modalOpen {
    height: 100%;
    background-color: white;
    z-index: 2000 !important;
}
